import React, { useState, useEffect, useRef } from 'react'; // Fixed import statement
import lodash from 'lodash';
import ReactMarkdown from 'react-markdown';
import { Send, Loader2 } from 'lucide-react'; // Import Loader2
import { Textarea } from './ui/textarea';
import { Button } from './ui/button';
import { sendKnowledgeBaseChatMessage, sendUserChatMessage, connectUserChats, connectKnowledgeBaseChats } from '../services/api'; // Updated import
import { Avatar, AvatarImage, AvatarFallback } from './ui/avatar';
import ErrorBoundary from './ui/ErrorBoundary';
import { useRecoilState } from 'recoil';
import { userState } from '../store/store';
import logo192 from '../images/logo192.png';
import { Separator } from './ui/separator';

const ChatInterface = ({ scope, userId, knowledgeBaseId }) => {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [chat, setChat] = useState(null);
    const chatWindowRef = useRef(null);
    const [user] = useRecoilState(userState);
    const [contentHeight, setContentHeight] = useState(600); // State to hold content height

    const fetchMessages = async () => {
        setLoading(true); // Show loader when fetching messages
        try {
            let response = null;
            if (scope === 'knowledge_base_chat') {
                const payload = { knowledge_base_id: knowledgeBaseId }
                response = await connectKnowledgeBaseChats(payload);
            } else if (scope === 'user_chat') {
                response = await connectUserChats(); // Connect to user chats
            }
            const chatData = lodash.get(response, ['data'], null);
            if (chatData) {
                setChat(chatData);
                setMessages(chatData.messages || []);
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
            setError('Failed to fetch messages. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMessages();
    }, [scope, user]);

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight; // Scroll to the agenttom
        }
    }, [messages]);

    const sendMessage = async () => {
        if (!message.trim()) {
            setError('Message cannot be empty.');
            return;
        }

        const userMessage = { content: 'Searching...', role: 'user', timestamp: new Date().toISOString() }; // Reverse roles
        const loadingMessage = { content: message, role: 'agent', timestamp: new Date().toISOString() }; // Reverse roles
        setMessages(prevMessages => [userMessage, loadingMessage, ...prevMessages]);

        setSending(true);
        try {
            const payload = { message, chat_id: chat?.id };
            let response;

            if (scope === 'knowledge_base_chat') {
                response = await sendKnowledgeBaseChatMessage({ ...payload, knowledge_base_id: knowledgeBaseId });
            } else if (scope === 'user_chat') {
                response = await sendUserChatMessage({ ...payload, user_id: userId }); // Send user chat message
            }

            if (response && response.data) {
                const chatObject = lodash.get(response, ['data', 'chat']);
                setChat(chatObject);
                setMessages(lodash.get(chatObject, 'messages', []));
                setMessage('');
                setError(null);
            } else {
                setError('Failed to send message.');
            }
        } catch (err) {
            console.error(err);
            setError('An error occurred while sending the message.');
        } finally {
            setSending(false);
        }
    };

    useEffect(() => {
        const updateHeight = () => {
            if (chatWindowRef.current) {
                const parent = chatWindowRef.current.parentElement;
                if (parent) {
                    setContentHeight(parent.clientHeight); // Set content height based on parent
                }
            }
        };

        // Create a ResizeObserver to observe changes in the parent element's size
        const resizeObserver = new ResizeObserver(updateHeight);
        if (chatWindowRef.current) {
            resizeObserver.observe(chatWindowRef.current.parentElement);
        }

        // Initial height update
        updateHeight();

        window.addEventListener('resize', updateHeight); // Update dimensions on window resize

        return () => {
            resizeObserver.disconnect(); // Cleanup observer
            window.removeEventListener('resize', updateHeight); // Cleanup event listener
        };
    }, []);

    return (
        <ErrorBoundary>
            <div className="flex flex-col h-full bg-background rounded-lg mb-2">
                <div className="flex-1 overflow-y-auto p-4" ref={chatWindowRef} style={{ maxHeight: `calc(${contentHeight}px - 100px)` }}>
                    {loading ? (
                        <div className="flex justify-center items-center h-full text-primary">
                            <Loader2 className="animate-spin h-6 w-6 mr-2" /> Connecting chat...
                        </div>
                    ) : (
                        messages.length === 0 ? (
                            <div className='flex flex-col items-center justify-center h-full'>
                                <p className="text-center text-xl text-gray-500">No chat messages found. Start the conversation!</p>
                                <p className="text-center text-md text-gray-500">Please enter your message below.</p>
                            </div>
                        ) : (
                            <div className="flex flex-col">
                                {messages.map((msg, index) => (
                                    <div key={index} className={`flex items-start justify-start`}>
                                        <div className={`message-bubble my-2 overflow-x-auto flex flex-col w-full `} >
                                            <Avatar className={'mb-2'} style={{ width: '32px', height: '32px' }}>
                                                <AvatarImage src={msg.role === 'agent' ? logo192 : user?.profile_image || "https://github.com/shadcn.png"} alt={msg.role} style={{ width: '32px', height: '32px' }} />
                                                <AvatarFallback>?</AvatarFallback>
                                            </Avatar>
                                            <p className="text-xs font-thin ">{new Date(msg.timestamp).toLocaleString()}</p>
                                            <p className="text-left text-md font-md" style={{ lineHeight: '1.75' }}><ReactMarkdown>{msg.content}</ReactMarkdown></p>

                                            <Separator className="my-2" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    )}
                </div>
                <div className="flex items-center rounded-lg border bg-background gap-4 p-1">
                    <Textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); sendMessage(); } }}
                        placeholder="Type your message..."
                        className={`chat-input flex-1 mx-2 border-none bg-background mr-2`}
                        rows={3}
                        disabled={sending}
                    />
                    <Button onClick={sendMessage} className="h-full" disabled={sending}>
                        {sending ? <Loader2 className="animate-spin h-6 w-6" /> : <Send className="h-6 w-6" />}
                    </Button>
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default ChatInterface;
