import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import lodash from 'lodash';
import { RefreshCw, Loader } from 'lucide-react';
// Custom components
import { Separator } from '../../../../components/ui/separator';
import { Button } from '../../../../components/ui/button';
import { Badge } from '../../../../components/ui/badge';
import { Skeleton } from '../../../../components/ui/skeleton';
import {
    TooltipProvider,
    Tooltip,
    TooltipTrigger,
    TooltipContent,
} from "../../../../components/ui/tooltip";
import { ResearchReportVersionList } from '../../../../components/ResearchReportVersionList';
import { StatusBadge } from '../../../../components/StatusBadge';
import { DetailsDialog } from '../../../../components/DetailsDialog';
import { DeleteReportDialog } from '../../reports/components/DeleteReportDialog';

const ReportVersionBadge = ({ version }) => {
    return (
        <Badge className={`text-xs capitalize mx-2 `} variant="secondary">
            Version {version}
        </Badge>
    )
}

export const ReportHeader = ({ report, loading, setSelectedReportVersion, selectedReportVersion, handleRefreshReport, startResearching, handleReportDeleted }) => {

    const [dialogStatus, setDialogStatus] = useState(false)

    if (loading) {
        return (
            <div className="flex flex-col text-left w-full">
                <div className="flex  w-full items-center px-2 py-1 justify-between">
                    <div className="flex flex-col items-start p-2 w-3/4">
                        <div className='flex w-full justify-start'>
                            <Skeleton className='w-[200px] my-1 h-[20px]' />
                        </div>
                        <Skeleton className='w-[300px] h-[15px]' />
                    </div>
                </div>
                <Separator />
            </div>
        )
    }
    return (
        <div className="flex flex-col text-left w-full">
            <div className="flex  w-full items-center px-2 py-1 justify-between">
                <div className="flex flex-col items-start p-2 w-3/4">
                    <div className='flex w-full justify-start items-center'>
                        <h2 className="text-lg font-semibold text-ellipsis max-w-lg text-primary mr-2">{lodash.get(report, ['title'])}</h2>
                        <StatusBadge status={lodash.get(report, ['status'])} />
                        <ReportVersionBadge version={selectedReportVersion + 1} />
                        <DetailsDialog heading={'Report Details'} title={lodash.get(report, ['title'])} description={lodash.get(report, ['description'])} />
                    </div>
                    <p className="text-xs w-full text-ellipsis max-w-2xl">{lodash.get(report, ['description'])}</p>
                </div>
                <div className='flex flex-row items-center'>
                    <DeleteReportDialog
                        report={report}
                        reportDeletedCb={() => handleReportDeleted(report, 'deleted')}
                        isOpen={dialogStatus}
                        setDialogStatus={setDialogStatus}
                    />
                    {handleRefreshReport ?
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button size="icon" variant="outline" className='mr-1' onClick={handleRefreshReport} >
                                        {lodash.get(startResearching, ['id']) === lodash.get(report, ['id']) ? <Loader /> : <RefreshCw className="h-4 w-4" />}
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent side="right">Re-generate Report</TooltipContent>
                            </Tooltip>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <ResearchReportVersionList report={report} selectedReportVersion={selectedReportVersion} setSelectedReportVersion={setSelectedReportVersion} />
                                </TooltipTrigger>
                                <TooltipContent side="right">Previous Reportst</TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        : null}
                    {/* <ResearchLogsListSheet items={sanitizedLogs} /> */}
                </div>

            </div>
            <Separator />
        </div>
    )
}

ReportHeader.propTypes = {
    report: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    setSelectedReportVersion: PropTypes.func.isRequired,
    selectedReportVersion: PropTypes.number.isRequired,
    sanitizedLogs: PropTypes.array.isRequired,
    handleRefreshReport: PropTypes.func,
    startResearching: PropTypes.object,
};
