import React, { useState } from 'react';
import lodash from 'lodash';
import {
    Card,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../../../../components/ui/card";
import { Eye, Edit } from 'lucide-react';
import { Badge } from "../../../../components/ui/badge";
import { Button } from "../../../../components/ui/button";
import { EditProductDialog } from "./EditProductDialog";
import { DeleteProductDialog } from './DeleteProductDialog';
import { StatusBadge } from '../../../../components/StatusBadge';
import { loadStripe } from '@stripe/stripe-js';

function ProductCard({ product, handleRedirectToProduct, handleRefreshProductList }) {
    const [editDialogStatus, setEditDialogStatus] = useState(null);
    const [deleteDialogStatus, setDeleteDialogStatus] = useState(null);

    const productReports = lodash.get(product, ['reports'], []);
    const reportInProgress = productReports.some(report => ['in_progress', 'queued'].includes(report.status)) ? 'in_progress' : 'completed';
    return (
        <Card className="w-full text-left">
            <CardHeader className='w-full text-left'>
                <CardTitle>{product.name}</CardTitle>
                <CardDescription className='text-ellipsis'> {product.description}</CardDescription>
            </CardHeader>
            <CardFooter className="flex justify-between">
                <div className='flex'>
                    {/* <StatusBadge status={reportInProgress} /> */}
                    <StatusBadge className='ml-2' status={`${productReports.length.toString()} reports`} />
                </div>
                <div className='flex'>
                    <Button variant='secondary' size="sm" className='m-1' onClick={(e) => { e.stopPropagation(); handleRedirectToProduct(product) }}>
                        <Eye className='w-4 h-4 ' />
                    </Button>
                    <DeleteProductDialog product={product} setDialogStatus={setDeleteDialogStatus} isOpen={deleteDialogStatus} productDeletedCb={handleRefreshProductList} />
                    <EditProductDialog product={product} setDialogStatus={setEditDialogStatus} isOpen={editDialogStatus} />
                </div>
            </CardFooter>
        </Card>
    )
}

export default ProductCard