import React, { useState } from "react";
import {
  Package,
  Home,
  ClipboardCheck,
  Users,
  ListTodo,
  BookOpen, // Importing icon for Knowledge Base
  MessageCircle, // Importing icon for Chat
  BarChart,
  DollarSign, // Importing icon for Billing
  Search
} from "lucide-react";
import { useRecoilState } from 'recoil';
import lodash from 'lodash';
import { useLocation, useHistory } from "react-router-dom";

import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../components/ui/tooltip";
import { Button } from "../../components/ui/button";
import { Separator } from "../../components/ui/separator";
import { FeedbackDialog } from "../../components/FeedbackDialog";
import { LatestReleasesDialog } from "../../components/LatestReleasesDialog"; // Import the new dialog
// Services
import { userState } from '../../store/store';

export const Sidebar = () => {
  const location = useLocation();
  const history = useHistory();

  const [user] = useRecoilState(userState);

  const getLinkClass = (route) => {
    if (lodash.get(location, ['pathname'], '').includes(route)) {
      return `bg-accent flex h-9 w-9 items-center justify-center rounded-sm text-accent-foreground transition-colors hover:text-foreground md:h-8 md:w-8 bg-primary text-primary-foreground`;
    }
    return `flex h-9 w-9 items-center justify-center rounded-sm text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8`;
  }

  const redirectToTab = (route) => {
    history.push(route)
  }

  return (
    <aside className="hidden w-14 flex-col border-r bg-background sm:flex main-area-section-h">
      <nav className="flex flex-col items-center gap-4 px-2 sm:py-5">
        <TooltipProvider>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className={getLinkClass('home')}
                variant="ghost" size="icon"
                onClick={() => { redirectToTab('/dashboard/home') }}
              >
                <Home className="h-5 w-5 transition-all group-hover:scale-110" />
                <span className="sr-only">Home</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right">Home</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost" size="icon"
                className={getLinkClass('knowledge-base')}
                onClick={() => { redirectToTab('/dashboard/knowledge-base') }}
              >
                <BookOpen className="h-5 w-5 transition-all group-hover:scale-110" />
                <span className="sr-only">Knowledge Base</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right">Knowledge Base</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost" size="icon"
                className={getLinkClass('research')}
                onClick={() => { redirectToTab('/dashboard/research') }}
              >
                <Search className="h-5 w-5 transition-all group-hover:scale-110" />
                <span className="sr-only">Research</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right">Research</TooltipContent>
          </Tooltip>

          {/* <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className={getLinkClass('analytics')}
                variant="ghost" size="icon"
                onClick={() => { redirectToTab('/dashboard/analytics') }}
              >
                <BarChart className="h-5 w-5 transition-all group-hover:scale-110" />
                <span className="sr-only">Analytics</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right">Analytics</TooltipContent>
          </Tooltip> */}

          {/* New Billing Tab */}
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost" size="icon"
                className={getLinkClass('billing')}
                onClick={() => { redirectToTab('/dashboard/billing') }}
              >
                <DollarSign className="h-5 w-5 transition-all group-hover:scale-110" />
                <span className="sr-only">Billing</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right">Billing</TooltipContent>
          </Tooltip>

          {lodash.get(user, 'role') === 'admin' ? <>
            <Separator />
            <>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost" size="icon"
                    className={getLinkClass('/admin/users')}
                    onClick={() => { redirectToTab('/admin/users') }}
                  >
                    <Users className="h-5 w-5 transition-all group-hover:scale-110" />
                    <span className="sr-only">Users</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="right">Users</TooltipContent>
              </Tooltip>

              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost" size="icon"
                    className={getLinkClass('/admin/reports')}
                    onClick={() => { redirectToTab('/admin/reports') }}
                  >
                    <ClipboardCheck className="h-5 w-5 transition-all group-hover:scale-110" />
                    <span className="sr-only">Reports</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="right">Reports</TooltipContent>
              </Tooltip>

              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost" size="icon"
                    className={getLinkClass('/admin/tasks')}
                    onClick={() => { redirectToTab('/admin/tasks') }}
                  >
                    <ListTodo className="h-5 w-5 transition-all group-hover:scale-110" />
                    <span className="sr-only">Tasks</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="right">Tasks</TooltipContent>
              </Tooltip>
            </>
          </> : null}
        </TooltipProvider>
      </nav >
      <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <LatestReleasesDialog />
            </TooltipTrigger>
            <TooltipContent side="right">Latest Releases</TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <FeedbackDialog />
            </TooltipTrigger>
            <TooltipContent side="right">Feedback</TooltipContent>
          </Tooltip>
        </TooltipProvider>

      </nav>
    </aside >
  );
};
