import { useEffect, useState, useCallback, useRef } from "react";
import lodash from 'lodash';
import { Edit } from 'lucide-react';

import "@blocknote/core/fonts/inter.css";
import { useCreateBlockNote } from "@blocknote/react";
import {
  BlockNoteView,
} from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { useTheme } from "../lib/theme-provider"
import ErrorBoundary from "./ui/ErrorBoundary";
import { Button } from "./ui/button"; // Import the Button component
import { Separator } from "./ui/separator";


export default function BlocknoteEditor({ content, autoSave, handleOnEditorUpdate }) {
  const { theme } = useTheme();
  const [enableEditing, setEnableEditing] = useState(false);

  // Inside your component
  const timeoutRef = useRef(null); // Step 1: useRef to store the timeout ID

  // Creates a new editor instance.
  const editor = useCreateBlockNote();

  // For initialization; on mount, convert the initial Markdown to blocks and replace the default editor's content
  useEffect(() => {
    async function loadInitialHTML() {
      const blocks = await editor.tryParseMarkdownToBlocks(content);
      editor.replaceBlocks(editor.document, blocks);
    }
    loadInitialHTML();
  }, [editor, content]);

  // Debounce the onChange handler
  const debouncedUpdate = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Step 2: Clear existing timeout
    }

    timeoutRef.current = setTimeout(async () => { // Step 3: Set a new timeout
      const markdown = await editor.blocksToMarkdownLossy();
      handleOnEditorUpdate(markdown);
    }, 6000); // Delay of 3 seconds
  }, [editor]); // Recreate this function only if `editor` changes

  const handleSave = async () => {
    const markdown = await editor.blocksToMarkdownLossy();
    handleOnEditorUpdate(markdown);
    setEnableEditing(false);
  }

  useEffect(() => {
    // Cleanup function to clear the timeout when the component unmounts or before the useEffect runs again
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Step 5: Clear the timeout on cleanup
      }
    };
  }, [debouncedUpdate]);

  // Renders the editor instance using a React component.
  return (
    <ErrorBoundary>
      <div className="overflow-auto text-left"> {/* Added scrollable container */}
        {!autoSave ? (
          <>
            <div className='p-2 rounded=lg'>
              {!enableEditing && !autoSave ? <div className='flex justify-end items-center px-2'>
                {/* <p className='font-bold text-blue-900'>Click edit to enable editing.</p> */}
                <Button variant='outline' className="" onClick={() => setEnableEditing(true)}>
                  <Edit className='w-4 h-4' />
                </Button>
              </div>
                : <div className='flex justify-between items-center'>
                  <p className='font-bold text-blue-900'>Click Save once you are done with editing.</p>
                  <div>
                    <Button className="mx-2" onClick={() => handleSave()}>
                      Save
                    </Button>
                    <Button variant='outline' className="mx-2" onClick={() => setEnableEditing(false)}>
                      Cancel
                    </Button>
                  </div>
                </div>}
            </div>
            <Separator />
          </>
        ) : null}
        <BlockNoteView
          editable={autoSave || enableEditing}
          className="typewriter min-w-full"
          editor={editor}
          theme={theme}
          onChange={debouncedUpdate}
          data-theming-css-variables-demo
        />
      </div>
    </ErrorBoundary>
  );
}
