import React, { useEffect, useState, useMemo } from 'react';
import { LoaderCircle, PlusCircle } from 'lucide-react';
import { Button } from "../../../components/ui/button";
import { Alert } from "../../../components/ui/alert";
import { AddKnowledgeBaseDialog } from '../products/components/AddKnowledgeBaseDialog';
import { getKnowledgeBases } from '../../../services/api';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '../../../components/ui/resizable';
import { KnowledgeBaseDisplay } from './KnowledgeBaseDisplay';
import { Separator } from '../../../components/ui/separator'; // Import the Separator component

import KnowledgeBaseList from './KnowledgeBaseList'; // Import the new component
import { logError, logDebug } from '../../../services/logging_utils'; // Import logging utilities
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbLink, BreadcrumbSeparator } from '../../../components/ui/breadcrumb'; // Import Breadcrumb components
import { useLocation, useHistory } from 'react-router-dom'; // Import useLocation and useHistory
import { calculateTokenCount } from '../../../services/api'; // Import the new API function
import lodash from 'lodash';

function KnowledgeBaseSection({ product, scope }) {
    const location = useLocation(); // Get the current location
    const history = useHistory(); // Get the history object
    const [knowledgeBases, setKnowledgeBases] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('All');

    const fetchKnowledgeBases = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await getKnowledgeBases(lodash.get(product, ['id'], null));
            const knowledgeBases = lodash.get(response, ['data', 'data'], []);
            setKnowledgeBases(knowledgeBases);

            // Get the knowledge_base id from the query params
            const params = new URLSearchParams(location.search);
            const knowledgeBaseId = params.get('knowledge_base');

            // Select the appropriate knowledge base
            const selectedBase = knowledgeBaseId && knowledgeBases.find(kb => lodash.get(kb, ['id'], null) === knowledgeBaseId)
                ? knowledgeBases.find(kb => lodash.get(kb, ['id'], null) === knowledgeBaseId)
                : lodash.get(knowledgeBases, [0], null);

            setSelectedKnowledgeBase(selectedBase);

            // Add the id of the first selected knowledge base to the URL if no knowledgeBaseId is present
            if (!knowledgeBaseId && selectedBase) {
                history.push(`?knowledge_base=${lodash.get(selectedBase, ['id'], '')}`);
            }

            logDebug('Knowledge bases fetched successfully.'); // Log successful fetch
        } catch (err) {
            setError('Failed to load knowledge bases.');
            logError(err); // Log the error
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchKnowledgeBases();
    }, [product]); // Re-fetch if the product prop changes

    const handleUpdateKnowledgeBase = async (kb) => {
        setShowDialog(false);
        await fetchKnowledgeBases();
    };

    const filteredKnowledgeBases = useMemo(() => {
        return knowledgeBases.filter(kb => {
            const isMatch = kb.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                kb.description.toLowerCase().includes(searchTerm.toLowerCase());
            return isMatch && (filterType === 'All' || kb.type === filterType);
        });
    }, [knowledgeBases, searchTerm, filterType]);

    if (loading) {
        return (
            <div className='h-full w-full flex flex-col justify-center items-center'>
                <LoaderCircle className='animate-spin' />
            </div>
        );
    }

    if (error) {
        return (
            <div className='h-full w-full flex flex-col justify-center items-center'>
                <Alert message={error} type="error" />
            </div>
        );
    }

    if (knowledgeBases.length === 0) {
        return (
            <div className=' w-full flex flex-col justify-center items-center h-full'>
                <p className='text-primary font-semibold text-xl'>No knowledge bases available.</p>
                <p className='text-muted-foreground text-md'>Add your first knowledge base to get started.</p>
                <AddKnowledgeBaseDialog
                    renderActionTrigger={() => (
                        <Button size='lg' className='text-lg font-semibold mt-4' onClick={() => setShowDialog(true)}>
                            Add Knowledge Base
                        </Button>
                    )}
                    isOpen={showDialog}
                    onClose={() => setShowDialog(false)}
                    product={product}
                    onUpdate={handleUpdateKnowledgeBase}
                />
            </div>
        );
    }

    const handleSelectKnowledgeBase = async (kb) => {
        setSelectedKnowledgeBase(kb);
        // Update the URL with the selected knowledge base id
        history.push(`?knowledge_base=${kb.id}`);
    };

    const handleOnEditorUpdate = (kb) => {
        logDebug('Refetching knowledge bases since knowledge bases were updated.'); // Log the update
        fetchKnowledgeBases();
    }

    return (
        <div className='flex flex-col'>
            <div className='flex justify-between items-center mx-4 my-2'>
                <Breadcrumb>
                    <BreadcrumbList className=''>
                        <BreadcrumbItem>
                            <BreadcrumbLink>Dashboard</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbLink>Knowledge Base</BreadcrumbLink>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>

                <AddKnowledgeBaseDialog
                    product={product}
                    isOpen={showAddDialog}
                    onClose={() => setShowAddDialog(false)}
                    onUpdate={handleUpdateKnowledgeBase}
                    renderActionTrigger={() => (
                        <Button
                            onClick={() => setShowAddDialog(true)}
                            className="rounded-md bg-blue-500 text-white shadow-lg"
                        >
                            <PlusCircle className="h-4 w-4 mr-2" /> Add
                        </Button>
                    )}
                />
            </div>
            <Separator />

            <ResizablePanelGroup direction="horizontal" className="h-full max-h-[calc(100vh-80px)] items-stretch">
                <ResizablePanel defaultSize={655} maxSize={25} minSize={25} className="overflow-auto">
                    <div className="relative overflow-auto h-full">
                        <KnowledgeBaseList
                            product={product}
                            isOpen={showAddDialog}
                            handleUpdateKnowledgeBase={handleUpdateKnowledgeBase}
                            knowledgeBases={filteredKnowledgeBases}
                            selectedKnowledgeBase={selectedKnowledgeBase}
                            onSelectKnowledgeBase={handleSelectKnowledgeBase}
                        />
                        {/* Show dot on tab if selectedKnowledgeBase is in the group */}
                        {selectedKnowledgeBase && selectedKnowledgeBase.group && (
                            <span className="dot-indicator" /> // Add your dot indicator styling here
                        )}
                    </div>
                </ResizablePanel>
                <ResizableHandle withHandle />
                <ResizablePanel defaultSize={975}>
                    <KnowledgeBaseDisplay
                        key={selectedKnowledgeBase?.id}
                        scope={scope}
                        knowledgeBase={selectedKnowledgeBase}
                        loading={loading}
                        handleOnEditorUpdate={handleOnEditorUpdate}
                    />
                </ResizablePanel>
            </ResizablePanelGroup>
        </div>
    );
}

export default KnowledgeBaseSection;
