import React, { useState } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';

import {
    Tabs,
    TabsList,
    TabsTrigger,
} from "../../../components/ui/tabs";

import BlocknoteEditor from "../../../components/BlocknoteEditor";
import { KnowledgeBaseHeader } from './KnowledgeBaseHeader';
import { Skeleton } from '../../../components/ui/skeleton';
import MindMapEditor from '../../../components/MindMapEditor';
import ChatInterface from '../../../components/ChatInterface';
import PreviewContent from './PreviewContent'; // Import the new PreviewContent component
import KnowledgeBaseDetails from './KnowledgeBaseDetails'; // Import the KnowledgeBaseDetails component
import SummaryEditor from '../../../components/SummaryEditor'; // Import the SummaryEditor component

export const KnowledgeBaseDisplay = ({ scope, knowledgeBase, loading, handleOnEditorUpdate }) => {
    const [activeTab, setActiveTab] = useState("summary"); // State to track the active tab

    if (loading) {
        return (
            <div className="flex h-full flex-col p-4 justify-start">
                <Skeleton className='w-[300px] h-[60px] my-1' />
                <Skeleton className='w-[400px] h-[40px] my-1' />
                <Skeleton className='w-full h-[40px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
                <Skeleton className='w-full h-[20px] my-1 mb-8' />

                <Skeleton className='w-[300px] h-[60px] my-1' />
                <Skeleton className='w-[400px] h-[40px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
            </div>
        )
    }

    if (!knowledgeBase) {
        return null
    }

    return (
        <>
            <div className="flex h-full flex-col justify-start">
                <div className="w-full h-full text-left">
                    <KnowledgeBaseHeader knowledgeBase={knowledgeBase} handleOnEditorUpdate={handleOnEditorUpdate} />
                    <Tabs className='h-full w-full'>
                        <TabsList className="grid grid-cols-3 m-1">
                            <TabsTrigger className={activeTab === "summary" ? 'bg-background' : 'bg-muted'} onClick={() => setActiveTab("summary")}>Summary</TabsTrigger>
                            <TabsTrigger className={activeTab === "chat" ? 'bg-background' : 'bg-muted'} onClick={() => setActiveTab("chat")}>Chat</TabsTrigger>
                            <TabsTrigger className={activeTab === "preview" ? 'bg-background' : 'bg-muted'} onClick={() => setActiveTab("preview")}>Preview</TabsTrigger>
                        </TabsList>

                        <div className={`border rounded-lg m-1 p-2 product-view_tab-content ${activeTab === "summary" ? "visible" : "hidden"}`}>
                            <SummaryEditor knowledgeBase={knowledgeBase} />
                        </div>

                        <div className={`h-full p-2 border rounded-lg m-1 product-view_tab-content ${activeTab === "chat" ? "visible" : "hidden"}`}>
                            <ChatInterface knowledgeBaseId={knowledgeBase.id} scope="knowledge_base_chat" />
                        </div>

                        <div className={`h-full p-2 border rounded-lg m-1 product-view_tab-content ${activeTab === "preview" ? "visible" : "hidden"}`}>
                            <PreviewContent knowledgeBase={knowledgeBase} />
                        </div>
                    </Tabs>
                </div>
            </div>
        </>
    )
}

KnowledgeBaseDisplay.propTypes = {
    knowledgeBase: PropTypes.object,
    loading: PropTypes.bool.isRequired,
};

KnowledgeBaseDisplay.defaultProps = {
    knowledgeBase: null,
};
