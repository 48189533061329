import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
// import { AdminLayout } from './layout';
import AdminDashboard from './pages/AdminDashboard';
import AdminUsers from './pages/AdminUsers';
import AdminKnowledgeBases from './pages/AdminKnowledgeBases';

function AdminWrapper() {
  const { path } = useRouteMatch();

  return (
    // <AdminLayout>
    <Switch>
      <Route exact path={path}>
        <AdminDashboard />
      </Route>
      <Route path={`${path}/users`}>
        <AdminUsers />
      </Route>
      <Route path={`${path}/knowledge-bases`}>
        <AdminKnowledgeBases />
      </Route>
    </Switch>
    // </AdminLayout>
  );
}

export default AdminWrapper;
