import React, { useState } from 'react';
import lodash from 'lodash';
import { RefreshCcw } from 'lucide-react';
import { Button } from '../../../../components/ui/button';
import { StatusBadge } from '../../../../components/StatusBadge';
import BlocknoteEditor from '../../../../components/BlocknoteEditor';
import { Tabs, TabsList, TabsTrigger } from '../../../../components/ui/tabs';
// Custom components
import { Separator } from '../../../../components/ui/separator';
import { Skeleton } from '../../../../components/ui/skeleton';
import { EditReportDialog } from "./EditReportDialog";
import { DeleteReportDialog } from './DeleteReportDialog';
import ChatInterface from '../../../../components/ChatInterface';
import { ReportHeader } from '../../products/components/ReportHeader';
// Services
import { updateReportContent } from '../../../../services/api';
import { logError } from "../../../../services/logging_utils";

export function ReportDisplay({
  report, loadingReport, onRefresh, onStartSetupClick
}) {
  const [activeTab, setActiveTab] = useState("report");
  const [updatingContent, setUpdatingContent] = useState(false);
  const [editDialogStatus, setEditDialogStatus] = useState(null);
  const [deleteDialogStatus, setDeleteDialogStatus] = useState(null);
  const [selectedReportVersion, setSelectedReportVersion] = useState(0);

  if (loadingReport && report === null) {
    return (
      <div className="flex h-full flex-col p-2 justify-start">
        <Skeleton className='w-[300px] h-[60px] my-1' />
        <Skeleton className='w-[400px] h-[40px] my-1' />
        <Skeleton className='w-full h-[40px] my-1' />
        <Skeleton className='w-full h-[20px] my-1' />
        <Skeleton className='w-full h-[20px] my-1' />
        <Skeleton className='w-full h-[20px] my-1 mb-8' />
      </div>
    );
  }

  if (!report) {
    return (
      <div className="report-display">
        <div className="report-header">
          <h2 className="text-xl font-semibold">No Report Selected</h2>
        </div>
        <div className="report-content">
          <p>Please select a report from the list to view its details.</p>
        </div>
      </div>
    );
  }

  const reportTitle = lodash.get(report, ['title'], 'Untitled Report');
  const reportStatus = lodash.get(report, ['status']);
  const reportDescription = lodash.get(report, ['description'], 'No description available.');

  const reportContent = lodash.get(report, ['content', selectedReportVersion, 'content'], '');
  const selectedReport = lodash.get(report, ['content', selectedReportVersion], {});
  const sanitzedReportContent = typeof reportContent === 'string' ? reportContent : (reportContent || []).filter((r) => r !== '' && typeof r === 'string').join('');



  const handleOnEditorUpdate = async (markdown) => {
    try {
      setUpdatingContent(true);
      const payload = {
        report_id: selectedReport.report_id,
        content: markdown,
        task_id: selectedReport.task_id,
        task: selectedReport.task,
        logs: selectedReport.logs
      }
      await updateReportContent({ taskId: selectedReport.task_id, payload: payload });

    } catch (error) {
      logError('Error updating report', error);
    } finally {
      setUpdatingContent(false);
    }
  }

  const handleRefreshReportList = () => {
    onRefresh();
  }

  return (
    <div className="flex flex-col h-full">
      <ReportHeader handleRefreshReport report={report} handleRedirectToAllReport={() => { }} sanitizedLogs={[]} loadingReport={loadingReport} selectedReportVersion={selectedReportVersion} setSelectedReportVersion={setSelectedReportVersion} />
      {/* <div className="report-header flex justify-between items-center p-4">
        <div className="flex flex-row items-center gap-2">
          <div className="flex flex-col text-left">
            <div className="flex gap-2">
              <h2 className="text-xl font-semibold">{reportTitle}</h2>
              <StatusBadge status={reportStatus} />
            </div>
            <p className="text-sm text-muted-foreground">{reportDescription}</p>
          </div>

        </div>
        <div className="gap-2 flex">
          <DeleteReportDialog report={report} setDialogStatus={setDeleteDialogStatus} isOpen={deleteDialogStatus} reportDeletedCb={handleRefreshReportList} />
          <EditReportDialog report={report} setDialogStatus={setEditDialogStatus} isOpen={editDialogStatus} />
          <Button onClick={onRefresh} variant="outline">
            <RefreshCcw className="h-4 w-4" />
          </Button>
        </div>
      </div> */}

      <Separator />
      <Tabs className="">
        <TabsList className="grid grid-cols-2 m-1">
          <TabsTrigger className={activeTab === "report" ? 'bg-background' : 'bg-muted'} onClick={() => setActiveTab("report")}>Report</TabsTrigger>
          <TabsTrigger className={activeTab === "chat" ? 'bg-background' : 'bg-muted'} onClick={() => setActiveTab("chat")}>Chat</TabsTrigger>
        </TabsList>
      </Tabs>

      <div className={`rounded-lg m-1 product-view_tab-content ${activeTab === "report" ? "visible" : "hidden"}`}>
        {sanitzedReportContent ? (
          <BlocknoteEditor content={sanitzedReportContent} updatingContent={updatingContent} handleOnEditorUpdate={handleOnEditorUpdate} autoSave={true} />
        ) : (
          <Button variant="outline" onClick={() => onStartSetupClick(report)}>
            Generate Report
          </Button>
        )}
      </div>
      <div className={`rounded-lg m-1 product-view_tab-content ${activeTab === "chat" ? "visible" : "hidden"}`}>
        <ChatInterface />
      </div>
    </div>
  );
}
