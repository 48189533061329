import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import lodash from 'lodash';
import { useRecoilState } from 'recoil';

// Components
import { Button } from '../components/ui/button';
import LoginForm from '../components/LoginForm';

// Services and Utils
import { setToken, setUser } from '../services/utils';
import { logError, trackAnalyticsEvent } from '../services/logging_utils';
import { checkUserSessionStatus, getUserAccessToken } from '../services/api';
import { userState, userSubscriptionState } from '../store/store';
import { logout } from '../lib/utils/auth_utils';

/**
 * LoginPage component handles user authentication and session management.
 * It retrieves the authentication token from the URL, fetches the user access token,
 * and manages user session state.
 */
export const LoginPage = () => {
    const history = useHistory();
    const [user, setUserState] = useRecoilState(userState);
    const [subscriptionState, setSubscriptionState] = useRecoilState(userSubscriptionState);

    const [state, setState] = useState({
        userLoggedIn: false,
        userName: null,
    });

    const authenticate = async () => {
        const tempauthToken = (window.location.search.match(/authToken=([^&]+)/) || [])[1];
        console.log("Temporary Auth Token:", tempauthToken);

        if (tempauthToken) {
            await getAccessToken(tempauthToken);
        }
        await checkUserSessionStatus();
    };

    const getAccessToken = async (authToken) => {
        try {
            const response = await getUserAccessToken({ authToken });
            const accessToken = lodash.get(response, ['data', 'access_token'], null);
            const user = lodash.get(response, ['data', 'user'], null);

            setToken(accessToken);
            setUser(user);
            await checkSessionStatus(accessToken);
        } catch (error) {
            logError(error);
        }
    };

    const checkSessionStatus = async () => {
        try {
            const response = await checkUserSessionStatus();
            const userLoggedIn = lodash.get(response, ['data', 'userLoggedIn'], false);
            const loggedInUser = lodash.get(response, ['data', 'user'], false);

            setUserState(loggedInUser);
            setSubscriptionState(lodash.get(loggedInUser, 'subscription_details', null));

            trackAnalyticsEvent("GOOGLE_LOGIN_SUCCESSFUL");
            if (userLoggedIn) {
                history.push('/dashboard/home');
            }
        } catch (error) {
            logError(error);
        }
    };

    useEffect(() => {
        (async () => {
            await authenticate();
        })();
    }, []);

    return (
        <section id="page-container">
            {state.userLoggedIn ? (
                <div>
                    <div>You are now logged in!</div>
                    <div>
                        <Button onClick={logout}>Logout</Button>
                    </div>
                </div>
            ) : (
                <LoginForm />
            )}
        </section>
    );
}
