import React from 'react';
import { Card, CardContent } from '../../../components/ui/card';
import ReportsSection from "./components/ReportsSection";

/**
 * ReportsView page combines the reports list and report chat view into a single unified interface.
 */
export default function ReportsView() {
    return (
        <Card>
            <CardContent>
                <ReportsSection />
            </CardContent>
        </Card>
    );
}