import React from 'react';
import lodash from 'lodash';
import {
    Card,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../../../../components/ui/card";

import { StatusBadge } from '../../../../components/StatusBadge';

/**
 * ReportCard component displays a single report with its details.
 * It allows users to view, edit, or delete the report.
 * 
 * Props:
 * - report: The report object containing details like title, description, and status.
 * - handleRedirectToReport: Function to redirect to the report details.
 * - handleRefreshReportList: Function to refresh the list of reports.
 * - selectedReport: The ID of the currently selected report.
 */
function ReportCard(props) {
    const { report, selectedReport, onSelect } = props;

    const isSelected = lodash.get(selectedReport, ['id']) === lodash.get(report, ['id']); // Check if the report is selected

    return (
        <Card className={`w-full text-left my-2 p-2 ${isSelected ? 'bg-muted' : ''}`} onClick={() => onSelect(report)}> {/* Highlight if selected */}
            <CardHeader className='w-full text-left p-2'>
                <CardTitle className='text-ellipsis text-lg '>{lodash.get(report, ['title'], 'Untitled Report')}</CardTitle>
                <CardDescription className='text-ellipsis text-xs m-0'> {lodash.get(report, ['description'], 'No description available.')}</CardDescription>
            </CardHeader>
            <CardFooter className="flex justify-between p-2">
                <StatusBadge status={lodash.get(report, ['status'], 'unknown')} />
            </CardFooter>
        </Card>
    )
}

export default ReportCard