import React from 'react';
import ChatInterface from '../../../../components/ChatInterface';

const ProductChatView = ({ product }) => {
    return (
        <div className="h-full"> {/* Ensure the parent has full height */}
            <ChatInterface scope="product_chat" userId="your_user_id" productId={product.id} /> {/* Pass the productId as a prop */}
        </div>
    );
};

export default ProductChatView;