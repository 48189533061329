import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LoaderCircle, PlusCircle } from 'lucide-react';
import lodash from 'lodash';

// Custom components
import { Button } from "../../../../components/ui/button";
import { Alert } from "../../../../components/ui/alert";
import AddReportDialog from "../components/AddReportDialog";
import ReportCard from '../components/ReportCard';
import { ReportDisplay } from '../components/ReportDisplay';
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbLink, BreadcrumbSeparator } from '../../../../components/ui/breadcrumb';
import { Separator } from '../../../../components/ui/separator';
import { Input } from '../../../../components/ui/input';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '../../../../components/ui/resizable';
import { startReportGeneration } from '../../reports_utils';

// Services
import { createReport, getReports, getReport } from '../../../../services/api';
import { logError, logDebug } from '../../../../services/logging_utils';

export default function ReportsSection() {
    const history = useHistory();
    const location = useLocation();

    const [reports, setReports] = useState([]);
    const [loadingReports, setLoadingReports] = useState(false);
    const [error, setError] = useState('');
    const [dialogStatus, setDialogStatus] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [loadingReport, setLoadingReport] = useState(false);
    const [reportDetails, setReportDetails] = useState(null);

    const [startResearching, setStartResearching] = useState(false);

    const fetchReports = async () => {
        setLoadingReports(true);
        setError('');
        try {
            const response = await getReports();
            const fetchedReports = lodash.get(response, ['data', 'data', 'reports'], []).filter(report => !report.product_id);
            setReports(fetchedReports);

            // Get the report id from the query params
            const params = new URLSearchParams(location.search);
            const reportId = params.get('report');

            // Select the appropriate report
            const selected = reportId && fetchedReports.find(r => r.id === reportId)
                ? fetchedReports.find(r => r.id === reportId)
                : fetchedReports[0];

            setSelectedReport(selected);

            // Add the id of the first selected report to the URL if no reportId is present
            if (!reportId && selected) {
                history.push(`?report=${selected.id}`);
            }

            logDebug('Reports fetched successfully.');
        } catch (err) {
            setError('Failed to load reports.');
            logError(err);
        } finally {
            setLoadingReports(false);
        }
    };

    const fetchReportDetails = async (reportId) => {
        setLoadingReport(true);
        try {
            const response = await getReport(reportId);
            const report = lodash.get(response, ['data', 'data'], {});
            setReportDetails(report);
        } catch (err) {
            setError('Failed to load report details.');
            logError(err);
        } finally {
            setLoadingReport(false);
        }
    };

    const onStartSetupClick = async (report) => {
        // setreportContent([]);
        // setSelectedReport(prevState => ({ ...prevState, content: '' }));

        setStartResearching(true);
        console.log('report', report);

        const requestData = {
            task: report.description,
            report_type: "detailed_report",
            report_source: "web",
            agent: "Auto Agent",
            report_id: report.id
        };

        await startReportGeneration({ setters: { setStartResearching, setSelectedReport: setSelectedReport }, data: requestData });
    };


    const handleRefresh = () => {
        if (selectedReport) {
            fetchReportDetails(selectedReport.id);
        }
    };

    useEffect(() => {
        fetchReports();
    }, []);

    useEffect(() => {
        if (selectedReport) {
            fetchReportDetails(selectedReport.id);
        }
    }, [selectedReport]);

    const handleReportCreation = async (payload) => {
        try {
            const response = await createReport({ payload });
            const report = lodash.get(response, ['data', 'data']);
            handleRedirectToReport(report);
            // setReportsView('REPORT_VIEW');
            setSelectedReport(report);
            history.push(`/dashboard/research/${lodash.get(report, ['id'], '')}`);
        } catch (error) {
            console.error("An error occurred:", error);
        } finally {
            setDialogStatus(false);
        }
    };

    const handleRedirectToReport = (report) => {
        setSelectedReport(report);
        history.push(`/dashboard/research/${report.id}`);
    };

    const filteredReports = useMemo(() => {
        return reports.filter(report => {
            return report.title.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [reports, searchTerm]);

    if (loadingReports) {
        return (
            <div className='h-full w-full flex flex-col justify-center items-center'>
                <LoaderCircle className='animate-spin' />
            </div>
        );
    }

    if (error) {
        return (
            <div className='h-full w-full flex flex-col justify-center items-center'>
                <Alert message={error} type="error" />
            </div>
        );
    }

    return (
        <div className='flex flex-col p-2 main-area-section-h main-area-section-w'>
            <div className='flex justify-between items-center m-2'>
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink>Dashboard</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbLink>Reports</BreadcrumbLink>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>

                <AddReportDialog
                    isOpen={dialogStatus}
                    onClose={() => setDialogStatus(false)}
                    onCreate={handleReportCreation}
                    handleReportCreation={handleReportCreation}
                    renderAction={() => {
                        return (
                            <Button onClick={() => setDialogStatus(true)} >
                                <PlusCircle className="mr-2" /> Add Report
                            </Button>
                        )
                    }}
                />
            </div>
            <Separator />
            {reports.length === 0 ? (
                <div className='w-full flex flex-col justify-center items-center h-full'>
                    <p className='text-primary font-semibold text-xl'>No reports available.</p>
                    <p className='text-muted-foreground text-md'>Add your first report to get started.</p>
                    <Button size='lg' className='text-lg font-semibold mt-4' onClick={() => setDialogStatus(true)}>
                        <PlusCircle className="h-4 w-4 mr-2" /> Add Report
                    </Button>
                </div>
            ) : (
                <ResizablePanelGroup direction="horizontal" className="h-full max-h-[calc(100vh-80px)] items-stretch">
                    <ResizablePanel defaultSize={655} maxSize={25} minSize={25} className="overflow-auto">
                        <div className="relative overflow-auto h-full p-2">
                            <Input
                                type="search"
                                placeholder="Search Reports..."
                                className="mb-2"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <section className="cursor-pointer" style={{ maxHeight: 'calc(100vh - 284px)', overflowY: 'auto' }}>
                                {filteredReports.map(report => (
                                    <ReportCard
                                        key={report.id}
                                        report={report}
                                        selectedReport={selectedReport}
                                        onSelect={() => setSelectedReport(report)}
                                    />
                                ))}
                            </section>
                        </div>
                    </ResizablePanel>
                    <ResizableHandle />
                    <ResizablePanel defaultSize={975}>
                        {selectedReport ? (
                            <ReportDisplay
                                report={reportDetails}
                                loading={loadingReport}
                                onRefresh={handleRefresh}
                                setSelectedReport={setSelectedReport}
                                selectedReport={selectedReport}
                                selectedReportVersion={0}
                                onStartSetupClick={onStartSetupClick}
                            />
                        ) : (
                            <div className='flex justify-center items-center h-full'>
                                <p>Select a report to view details.</p>
                            </div>
                        )}
                    </ResizablePanel>
                </ResizablePanelGroup>
            )}
        </div>
    );
} 