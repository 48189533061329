import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from './ui/badge';

export const StatusBadge = ({ status, className }) => {
    if (!status) return null;

    const getStatusColor = (status) => {
        const currentStatus = status || 'unknown';
        switch (currentStatus) {
            case 'in_progress':
                return 'bg-blue-500';
            case 'completed':
                return 'bg-green-500 text-white';
            case 'pending':
                return 'bg-yellow-500 text-white';
            case 'failed':
                return 'bg-red-500';
            default:
                return 'bg-gray-500';
        }
    };

    const statusColor = getStatusColor(status);

    return (
        <Badge className={`text-xs capitalize ${statusColor} ${className}`} variant="secondary">
            {(status || 'UNKNOWN').replace(/_/g, ' ')}
        </Badge>
    )
}

StatusBadge.propTypes = {
    status: PropTypes.string.isRequired,
};